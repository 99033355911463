<template>
    <div>
      <!-- begin:: Header -->
      <div
        id="kt_header"
        ref="kt_header"
        class="header"
        v-bind:class="headerClasses"
      >
        <div
          class="container-fluid d-flex align-items-center justify-content-between"
        >
          <!-- begin:: Header Menu -->
          <div
            class="header-menu-wrapper header-menu-wrapper-left"
            ref="kt_header_menu_wrapper"
          >
            <div
              v-if="headerMenuEnabled"
              id="kt_header_menu"
              ref="kt_header_menu"
              class="header-menu header-menu-mobile"
              v-bind:class="headerMenuClasses"
            >
              <!-- example static menu here -->
              <ul
                class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2"
              >
                <template>
                  <li class="breadcrumb-item">
                  <span style="font-size: 12px;">
                    MASTER / <span style="font-weight: 600;">SUB SATUAN/NON SATUAN KERJA</span>
                  </span>
                </li>
                </template>
              </ul>
            </div>
          </div>
          <!-- end:: Header Menu -->
          <!-- begin:: Header Topbar -->
          <div class="topbar">
            <!--begin: User Bar -->
            <div class="topbar-item">
              <!--begin: Notifications -->
              <b-dropdown
                size="m"
                variant="link"
                toggle-class="topbar-item text-decoration-none"
                no-caret
                right
                no-flip
                class="px-2 mr-4"
              >
                <template v-slot:button-content>
                  <notification-bell
                    :size="20"
                    :count="totalNotification"
                    :ding="true"
                    :upperLimit="50"
                    counterLocation="upperRight"
                    counterStyle="roundRectangle"
                    counterBackgroundColor="#FF0000"
                    counterTextColor="#FFFFFF"
                    iconColor="#1e2758"
                    :animated="false"
                    fontSize="120"
                  />
                </template>
                <b-dropdown-text tag="div" class="min-w-md-350px">
                  <form>
                    <b-tabs>
                      <b-tab>
                        <perfect-scrollbar
                          class="navi navi-hover scroll my-4"
                          style="max-height: 40vh; position: relative;"
                        >
                          <router-link
                            :to="{
                              name: 'bank-in',
                              params: {
                                bankTransactionPermission: 'BM'
                              }
                            }"
                            v-slot="{ href, navigate }"
                            v-if="count_escrow_notification > 0"
                          >
                            <a
                              :href="href"
                              class="navi-item"
                              v-for="i in data_escrow_notification"
                              v-bind:key="i"
                              @click="navigate"
                            >
                              <div class="navi-link">
                                <div class="navi-icon mr-2">
                                  <i class="flaticon2-bell"></i>
                                </div>
                                <div class="navi-text">
                                  <div class="font-weight-bold">
                                    ESCROW
                                  </div>
                                  <div class="text-muted">
                                    ESCROW DITERIMA
                                  </div>
                                </div>
                              </div>
                            </a>
                          </router-link>
                          <router-link
                            :to="{
                              name: 'guarantee'
                            }"
                            v-slot="{ href, navigate }"
                            v-if="count_bank_guarantee_notification > 0"
                          >
                            <a
                              :href="href"
                              class="navi-item"
                              v-for="i in data_bank_guarantee_notification"
                              v-bind:key="i"
                              @click="navigate"
                            >
                              <div class="navi-link">
                                <div class="navi-icon mr-2">
                                  <i class="flaticon2-bell"></i>
                                </div>
                                <div class="navi-text">
                                  <div class="font-weight-bold">
                                    PENCAIRAN
                                  </div>
                                  <div class="text-muted">
                                    PENCAIRAN BANK GARANSI
                                  </div>
                                </div>
                              </div>
                            </a>
                          </router-link>
                          <router-link
                            :to="{
                              name: 'finance'
                            }"
                            v-slot="{ href, navigate }"
                            v-if="count_submission_form_finance_notification > 0"
                          >
                            <a
                              :href="href"
                              class="navi-item"
                              v-for="i in data_submission_form_finance_notification"
                              v-bind:key="i"
                              @click="navigate"
                            >
                              <div class="navi-link">
                                <div class="navi-icon mr-2">
                                  <i class="flaticon2-bell"></i>
                                </div>
                                <div class="navi-text">
                                  <div class="font-weight-bold">
                                    PENCAIRAN KE BANK GARANSI
                                  </div>
                                  <div class="text-muted">
                                    PENCAIRAN KE BANK GARANSI
                                  </div>
                                </div>
                              </div>
                            </a>
                          </router-link>
                        </perfect-scrollbar>
                      </b-tab>
                    </b-tabs>
                  </form>
                </b-dropdown-text>
              </b-dropdown>
              <!--end: Notifications -->
              <div
                class="btn btn-icon w-auto d-flex align-items-center btn-lg px-2"
                id="kt_quick_user_toggle"
              >
                <span
                  class="symbol symbol-30 symbol-circle symbol-xl-30 pr-4 symbol-light-success"
                >
                  <img v-if="false" alt="Profile Photo" :src="currentUserPhoto" />
                  <span
                    v-if="true"
                    class="symbol-label font-size-h5 font-weight-bold"
                  >
                    {{ user_fullname.charAt(0).toUpperCase() }}
                  </span>
                </span>
                <span
                  class="d-none d-md-inline mr-12"
                  v-bind:style="{
                    'font-family': 'lato',
                    'font-size': '12px',
                    'font-weight': '600'
                  }"
                >
                  <span class="font-weight-bold font-size-base d-md-inline mr-1">
                    HI,
                  </span>
                  {{ user_fullname }}
                </span>
                <i class="ki ki-arrow-down icon-xs text-muted mr-12"></i>
              </div>
  
              <div
                id="kt_quick_user"
                ref="kt_quick_user"
                class="offcanvas offcanvas-right p-10"
              >
                <!--begin::Header-->
                <div
                  class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
                >
                  <h3 class="font-weight-bold m-0">
                    PROFIL USER
                  </h3>
                  <a
                    href="#"
                    class="btn btn-xs btn-icon btn-light btn-hover-primary"
                    id="kt_quick_user_close"
                  >
                    <i class="ki ki-close icon-xs text-muted"></i>
                  </a>
                </div>
                <!--end::Header-->
  
                <!--begin::Content-->
                <perfect-scrollbar
                  class="offcanvas-content pr-5 mr-n5 scroll"
                  style="max-height: 90vh; position: relative;"
                >
                  <!--begin::Header-->
                  <div class="d-flex align-items-center mt-5">
                    <div class="symbol symbol-100 mr-5">
                      <img :src="currentUserPersonalInfo.photo" alt="" />
                      <i class="symbol-badge bg-success"></i>
                    </div>
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold font-size-h5 text-dark-75">
                        {{ user_fullname }}
                      </span>
                      <div class="navi mt-2">
                        <span class="navi-link p-0 pb-2">
                          <span class="navi-icon mr-1">
                            <span class="svg-icon svg-icon-lg svg-icon-primary">
                              <!--begin::Svg Icon-->
                              <inline-svg src="assets/icons/Mail.svg" />
                              <!--end::Svg Icon-->
                            </span>
                          </span>
                          <span class="navi-text text-muted">
                            {{ email }}
                          </span>
                        </span>
                      </div>
                      <button
                        class="btn btn-light-primary btn-bold"
                        @click="onLogout"
                      >
                        SIGN OUT
                      </button>
                    </div>
                  </div>
                  <!--end::Header-->
                  <div class="separator separator-dashed mt-8 mb-5"></div>
                  <div class="separator separator-dashed my-7"></div>
                </perfect-scrollbar>
                <!--end::Content-->
              </div>
            </div>
            <!--end: User Bar -->
          </div>
          <!-- end:: Header Topbar -->
        </div>
      </div>
      <!-- end:: Header -->
      <!-- Body -->
      <div style="margin-bottom: 43px;">
      <div>
        <img class="mb-4" src="assets/background_page_title.png" alt="" style="height: 36px; margin-right: 10px;">
        <span style="font-size: 30px; font-weight: 700;">
          SUB SATUAN/NON SATUAN KERJA
        </span>
      </div>
      <div>
        <span>
          MENAMPILKAN DAN MEMBUAT DATA MASTER
        </span>
      </div>
    </div>
      <div class="form-group">
        <template>
          <v-toolbar flat>
            <v-dialog v-model="dialog" max-width="800px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="white"
                  light
                  class="mb-12"
                  v-bind="attrs"
                  v-on="on"
                  style="text-transform: capitalize !important; min-height: 48px;"
                >
                  BUAT MASTER BARU +
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
  
                <v-card-text>
                  <v-container>
                    <v-col>
                      <v-row>
                        <v-col cols="3" class="mt-4">
                          <span style="color: black; font-size: 14px;"
                            >UNIT MATRA<span style="color: red;">*</span></span
                          >
                        </v-col>
                        <v-col cols="9">
                          <v-select
                            v-model="formInput.organizational_unit_id"
                            id="organizational_unit_id"
                            :items="data_organizational_units_combobox"
                            @click="dataOrganizationalUnits_Combobox"
                            item-value="organizational_unit_id"
                            item-text="organizational_unit_name"
                            class="vuetify-custom"
                            solo
                            v-bind:style="{
                              color: '#1e2758 !important'
                            }"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3" class="mt-4">
                          <span style="color: black; font-size: 14px;">
                            SUB UNIT MATRA<span style="color: red;">*</span>
                          </span>
                        </v-col>
                        <v-col cols="9">
                          <v-select
                            v-model="formInput.sub_organizational_unit"
                            id="sub_organizational_unit_id"
                            :items="data_sub_organizational_unit_combobox"
                            item-value="sub_organizational_unit_id"
                            item-text="sub_organizational_unit_name"
                            class="vuetify-custom"
                            solo
                            v-bind:style="{ color: '#1e2758 !important' }"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3" class="mt-4">
                          <span style="color: black; font-size: 14px;"
                            >SATUAN KERJA/NON SATUAN KERJA<span style="color: red;">*</span></span
                          >
                        </v-col>
                        <v-col cols="9">
                          <v-select
                            v-model="formInput.work_unit_id"
                            id="work_unit_id"
                            :items="data_work_units_combobox"
                            @click="dataWorkUnits_Combobox"
                            item-value="work_unit_id"
                            item-text="work_unit_name"
                            class="vuetify-custom"
                            solo
                            v-bind:style="{
                              color: '#1e2758 !important'
                            }"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3" class="mt-4">
                          <span style="color: black; font-size: 14px;"
                            >NAMA<span style="color: red;">*</span></span
                          >
                        </v-col>
                        <v-col cols="9">
                          <v-text-field
                            v-model="formInput.sub_work_unit_name"
                            class="vuetify-custom"
                            solo
                            v-bind:style="{
                              color: '#1e2758 !important'
                            }"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-container>
                </v-card-text>
  
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    class="mb-4 btn btn-outline-secondary"
                    @click="close"
                  >
                    BATALKAN
                  </v-btn>
                  <v-btn
                    color="primary"
                    light
                    class="mb-4"
                    @click="formSubmit"
                    style="text-transform: capitalize !important; color: white !important;"
                  >
                    SIMPAN MASTER BARU
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="CARI SUB SATUAN/NON SATUAN KERJA"
              solo
              hide-details
              class="mb-12"
              style="width: 10px; box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);"
            ></v-text-field>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >APAKAH YAKIN UNTUK MENGHAPUS DATA INI?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="closeDelete">BATAL</v-btn>
                  <v-btn
                    text
                    @click="deleteDataSubWorkUnits"
                    v-bind:style="{
                      color: '#1e2758 !important'
                    }"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="data_sub_work_units"
            :item-key="work_unit_id"
            :options.sync="options"
            :search="search"
            sort-by="Id"
            class="elevation-1"
            :loading="loadingTable"
            loading-text="HARAP TUNGGU..."
          >
            <template v-slot:[`item.number`]="{ index }">
              <td>
                {{
                  options.page * options.itemsPerPage -
                    options.itemsPerPage +
                    (index + 1)
                }}
              </td>
            </template>
            <template v-slot:[`item.aksi`]="{ item }">
              <v-btn
                color="green"
                light
                small
                class="mr-3"
                @click="editItem(item)"
                data-toggle="tooltip"
                title="EDIT"
                style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
              >
                <v-icon small color="white">
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                color="red"
                light
                small
                @click="deleteItem(item)"
                data-toggle="tooltip"
                title="HAPUS"
                style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
              >
                <v-icon small color="white">
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </template>
      </div>
      <!-- End Body -->
    </div>
  </template>
  
  <style lang="scss">
  .topbar {
    .dropdown-toggle {
      padding: 0;
      &:hover {
        text-decoration: none;
      }
  
      &.dropdown-toggle-no-caret {
        &:after {
          content: none;
        }
      }
    }
  
    .dropdown-menu {
      margin: 0;
      padding: 0;
      outline: none;
      .b-dropdown-text {
        padding: 0;
      }
    }
  }
  </style>
  
  <style lang="scss" scoped>
  #kt_quick_user {
    overflow: hidden;
  }
  </style>
  
  <style scoped>
  .vuetify-custom >>> .v-text-field__slot label {
    color: #1e2758 !important;
  }
  
  .vuetify-custom >>> .v-select__slot label {
    color: #1e2758 !important;
  }
  
  .vuetify-custom >>> .v-input__icon i {
    color: #1e2758 !important;
  }
  </style>
  
  <script>
  import { mapGetters } from "vuex";
  import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
  import KTLayoutHeader from "@/assets/js/layout/base/header.js";
  import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
  import { LOGOUT } from "@/core/services/store/auth.module";
  import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
  import KTOffcanvas from "@/assets/js/components/offcanvas.js";
  import NotificationBell from "vue-notification-bell";
  import localStorage from "@/core/services/store/localStorage";
  import Services from "@/core/services/app_internal/Services";
  import ApiService from "@/core/services/api.service";
  import Swal from "sweetalert2";
  import "@/core/plugins/vuetify-money.js";
  
  export default {
    name: "master-work-units",
  
    components: {
      NotificationBell
    },
  
    data: () => ({
      search: "",
      dialog: false,
      dialogDelete: false,
      create_data_sub_work_units: [],
      update_data_sub_work_units: [],
      delete_data_sub_work_units: [],
      data_sub_work_units: [],
      data_escrow_notification: [],
      data_bank_guarantee_notification: [],
      data_submission_form_finance_notification: [],
      data_organizational_units_combobox: [],
      data_sub_organizational_unit_combobox: [],
      data_work_units_combobox: [],
      data_sub_work_units_date: {
        date: new Date().toISOString().substr(0, 10)
      },
      add_sub_work_units: {
        organizational_unit_id: "",
        organizational_unit_name: "",
        work_unit_id: "",
        work_unit_name: "",
        sub_work_unit_id: "",
        sub_work_unit_name: ""
      },
      submitted: false,
      headers: [],
      editedIndex: -1,
      deletedIndex: -1,
      editedItem: {},
      deletedItem: {},
      defaultItem: {},
      isFirstLoad: true,
      options: {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false
      },
      label: "",
      placeholder: "",
      readonly: false,
      disabled: false,
      outlined: false,
      clearable: false,
      valueWhenIsEmpty: "",
      option: {
        locale: "en-US",
        prefix: "",
        suffix: "",
        length: 20,
        precision: 0
      },
      date: "",
      user_role: "",
      user_id: "",
      user_fullname: "",
      email: "",
      count_escrow_notification: 0,
      count_bank_guarantee_notification: 0,
      count_submission_form_finance_notification: 0,
      loadingTable: true
    }),
  
    computed: {
      ...mapGetters([
        "layoutConfig",
        "getClasses",
        "currentUserPersonalInfo",
        "currentUserPhoto"
      ]),
  
      headerMenuEnabled() {
        return !!this.layoutConfig("header.menu.self.display");
      },
  
      headerClasses() {
        const classes = this.getClasses("header");
        if (typeof classes !== "undefined") {
          return classes.join(" ");
        }
        return null;
      },
  
      headerMenuClasses() {
        const classes = this.getClasses("header_menu");
        if (typeof classes !== "undefined") {
          return classes.join(" ");
        }
        return null;
      },
  
      totalNotification: function() {
        let countEscrowNotification = this.count_escrow_notification;
        let countBankGuaranteeNotification = this
          .count_bank_guarantee_notification;
        let countSubmissionFormFinanceNotification = this
          .count_submission_form_finance_notification;
        let totalNotification = 0;
  
        totalNotification += parseInt(
          countEscrowNotification +
            countBankGuaranteeNotification +
            countSubmissionFormFinanceNotification
        );
  
        return totalNotification;
      },
  
      config() {
        return this.layoutConfig();
      },
  
      formTitle() {
        return this.editedIndex === -1
          ? "BUAT MASTER SUB SATUAN/NON SATUAN KERJA"
          : "UBAH MASTER SUB SATUAN/NON SATUAN KERJA";
      },
  
      formInput() {
        return this.editedIndex === -1 ? this.add_sub_work_units : this.editedItem;
      },
  
      formSubmit() {
        return this.editedIndex === -1
          ? this.createDataSubWorkUnits
          : this.updateDataSubWorkUnits;
      },
  
      computedDate() {
        return this.formatDate(this.date);
      }
    },
  
    watch: {
      dialog(val) {
        val || this.close();
      },
  
      dialogDelete(val) {
        val || this.closeDelete();
      },
  
      options: {
        async handler(val) {
          val;
          if (!this.isFirstLoad) await this.dataDoMasuk();
          this.loading = false;
        },
        deep: true
      },
  
      date() {
        this.formInputNotClear.date = this.formatDate(this.date);
      }
    },
  
    created() {
      this.initHeders();
      this.initSubOrganizationalUnitCombobox();
    },
  
    mounted() {
      // Init Desktop & Mobile Headers
      KTLayoutHeader.init("kt_header", "kt_header_mobile");
  
      // Init Header Menu
      KTLayoutHeaderMenu.init(
        this.$refs["kt_header_menu"],
        this.$refs["kt_header_menu_wrapper"]
      );
  
      const headerRef = this.$refs["kt_header"];
  
      headerRef.querySelectorAll("a[class='menu-link']").forEach(item => {
        item.addEventListener("click", () => {
          KTLayoutHeaderMenu.getOffcanvas().hide();
        });
      });
  
      this.load();
  
      // Init Quick User Panel
      KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  
      this.user_id = localStorage.getLocalStorage("UID");
      this.user_fullname = localStorage.getLocalStorage("User_Fullname");
      this.email = localStorage.getLocalStorage("User_Email");
      this.user_role = localStorage.getLocalStorage("User_Role");
  
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Master Sub Satuan Kerja" }]);
    },
  
    methods: {
      onLogout() {
        this.$store
          .dispatch(LOGOUT)
          .then(() => this.$router.push({ name: "login" }));
      },
  
      closeOffcanvas() {
        new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
      },
  
      getHeaders() {
        return [
          {
            text: "Id",
            sortable: false,
            value: "work_unit_id",
            align: " d-none"
          },
          { text: "NO", value: "number" },
          {
            text: "UNIT MATRA",
            sortable: true,
            value: "organizational_unit_name"
          },
          {
            text: "SUB UNIT MATRA",
            sortable: true,
            value: "sub_organizational_unit"
          },
          {
            text: "SATUAN/NON SATUAN KERJA",
            sortable: true,
            value: "work_unit_name"
          },
          {
            text: "NAMA",
            sortable: true,
            value: "sub_work_unit_name"
          },
          {
            text: "EDIT",
            value: "aksi",
            sortable: false
          }
        ];
      },

      getSubOrganizationalUnitCombobox() {
        return [
            {
            sub_organizational_unit_id: "HO",
            sub_organizational_unit_name: "HO"
            },
            {
            sub_organizational_unit_id: "Satker",
            sub_organizational_unit_name: "Satker"
            },
            {
            sub_organizational_unit_id: "Non-Satker",
            sub_organizational_unit_name: "Non-Satker"
            }
        ];
      },
  
      loadDate: function() {
        this.date = new Date().toISOString().substr(0, 10);
        this.formInputNotClear.date = this.formatDate(
          new Date().toISOString().substr(0, 10)
        );
      },
  
      formatDate(date) {
        if (!date) return null;
  
        var arr = date.split("-"),
          year = arr[0],
          month = arr[1],
          day = arr[2];
        return `${day}-${month}-${year}`;
      },
  
      parseDate: function(date) {
        if (!date) return null;
  
        var arr = date.split("/"),
          day = arr[0],
          month = arr[1],
          year = arr[2];
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      },
  
      onChangeDate(item) {
        this.$refs.date_date.save(item);
        this.data_sub_work_units_date.date = item;
      },
  
      createDataSubWorkUnits() {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "C",
            Token: "C",
            UID: localStorage.getLocalStorage("UID"),
            Route: "DEFAULT",
            organizational_unit_id: this.add_sub_work_units.organizational_unit_id,
            sub_organizational_unit: this.add_sub_work_units.sub_organizational_unit,
            work_unit_id: this.add_sub_work_units.work_unit_id,
            sub_work_unit_name: this.add_sub_work_units.sub_work_unit_name,
            status: 1,
            created_by: localStorage.getLocalStorage("User_Fullname")
          };
  
          let contentType = `application/x-www-form-urlencoded`;
  
          const qs = require("qs");
  
          Services.PostData(
            ApiService,
            "Master/SubWorkUnits",
            qs.stringify(mydata),
            contentType,
            response => {
              resolve(response.data);
              if (response.status == 1000) {
                Swal.fire({
                  title: "",
                  text: "BERHASIL MENAMBAH DATA",
                  icon: "success",
                  heightAuto: true,
                  timer: 1500
                });
              } else {
                Swal.fire({
                  title: "",
                  text: response.message,
                  icon: "info",
                  heightAuto: true,
                  timer: 1500
                });
              }
              this.create_data_sub_work_units = response.data;
              this.submitted = true;
              this.save("add_sub_work_units");
              this.loadDataSubWorkUnits();
            },
            err => {
              err;
            }
          );
        });
      },
  
      updateDataSubWorkUnits() {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "U",
            Token: "U",
            UID: localStorage.getLocalStorage("UID"),
            Route: "DEFAULT",
            sub_work_unit_id: this.editedItem.sub_work_unit_id,
            organizational_unit_id: this.editedItem.organizational_unit_id,
            sub_organizational_unit: this.editedItem.sub_organizational_unit,
            work_unit_id: this.editedItem.work_unit_id,
            sub_work_unit_name: this.editedItem.sub_work_unit_name,
            status: 1,
            modified_by: localStorage.getLocalStorage("User_Fullname"),
            date_modified: this.data_sub_work_units_date.date
          };
  
          let contentType = `application/x-www-form-urlencoded`;
  
          const qs = require("qs");
  
          Services.PostData(
            ApiService,
            "Master/SubWorkUnits",
            qs.stringify(mydata),
            contentType,
            response => {
              resolve(response.data);
              if (response.status == 1000) {
                Swal.fire({
                  title: "",
                  text: "BERHASIL MEMPERBAHARUI DATA",
                  icon: "success",
                  heightAuto: true,
                  timer: 1500
                });
              } else {
                Swal.fire({
                  title: "",
                  text: response.message,
                  icon: "info",
                  heightAuto: true,
                  timer: 1500
                });
              }
              this.update_data_sub_work_units = response.data;
              this.submitted = true;
              this.save("edit_sub_work_units");
              this.loadDataSubWorkUnits();
            },
            err => {
              err;
            }
          );
        });
      },
  
      deleteDataSubWorkUnits() {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "D",
            Token: "D",
            UID: localStorage.getLocalStorage("UID"),
            Route: "DEFAULT",
            sub_work_unit_id: this.deletedItem.sub_work_unit_id
          };
  
          let contentType = `application/x-www-form-urlencoded`;
  
          const qs = require("qs");
  
          Services.PostData(
            ApiService,
            "Master/SubWorkUnits",
            qs.stringify(mydata),
            contentType,
            response => {
              resolve(response.data);
              if (response.status == 1000) {
                Swal.fire({
                  title: "",
                  text: "BERHASIL MENGHAPUS DATA",
                  icon: "success",
                  heightAuto: true,
                  timer: 1500
                });
              } else {
                Swal.fire({
                  title: "",
                  text: response.message,
                  icon: "info",
                  heightAuto: true,
                  timer: 1500
                });
              }
              this.delete_data_sub_work_units = response.data;
              this.submitted = true;
              this.data_sub_work_units.splice(this.deletedIndex, 1);
              this.closeDelete();
            },
            err => {
              err;
            }
          );
        });
      },
  
      dataSubWorkUnits() {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "R",
            Token: "R",
            UID: localStorage.getLocalStorage("UID"),
            Route: "DEFAULT"
          };
  
          let contentType = `application/x-www-form-urlencoded`;
  
          const qs = require("qs");
  
          Services.PostData(
            ApiService,
            "Master/SubWorkUnits",
            qs.stringify(mydata),
            contentType,
            response => {
              resolve(response.data);
              this.data_sub_work_units = response.data;
              this.loadingTable = false;
            },
            err => {
              err;
            }
          );
        });
      },
  
      dataOrganizationalUnits_Combobox() {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "R",
            Token: "R",
            UID: localStorage.getLocalStorage("UID"),
            Route: "COMBOBOX"
          };
  
          let contentType = `application/x-www-form-urlencoded`;
  
          const qs = require("qs");
  
          Services.PostData(
            ApiService,
            "Master/OrganizationalUnits",
            qs.stringify(mydata),
            contentType,
            response => {
              resolve(response.data);
              this.data_organizational_units_combobox = response.data;
            },
            err => {
              err;
            }
          );
        });
      },
  
      dataWorkUnits_Combobox() {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "R",
            Token: "R",
            UID: localStorage.getLocalStorage("UID"),
            Route: "COMBOBOX"
          };
  
          let contentType = `application/x-www-form-urlencoded`;
  
          const qs = require("qs");
  
          Services.PostData(
            ApiService,
            "Master/WorkUnits",
            qs.stringify(mydata),
            contentType,
            response => {
              resolve(response.data);
              this.data_work_units_combobox = response.data;
            },
            err => {
              err;
            }
          );
        });
      },
  
      dataEscrowPaymentAccepted() {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "R",
            Token: "R",
            UID: localStorage.getLocalStorage("UID"),
            Route: "ESCROW_PAYMENT_ACCEPTED",
            payment_accepted: "E",
            status_receipt: 1,
            status_escrow_accepted: 0
          };
  
          let contentType = `application/x-www-form-urlencoded`;
  
          const qs = require("qs");
  
          Services.PostData(
            ApiService,
            "Transaction/BankTransaction",
            qs.stringify(mydata),
            contentType,
            response => {
              resolve(response.data);
              this.data_escrow_notification = response.data;
              this.count_escrow_notification =
                this.user_role.toLowerCase() === "atasan keuangan"
                  ? response.data.length
                  : 0;
            },
            err => {
              err;
            }
          );
        });
      },
  
      dataBankGuaranteeNotification() {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "R",
            Token: "R",
            UID: localStorage.getLocalStorage("UID"),
            Route: "NOTIFICATION_BANK_GUARANTEE",
            status_cashing: 0
          };
  
          let contentType = `application/x-www-form-urlencoded`;
  
          const qs = require("qs");
  
          Services.PostData(
            ApiService,
            "Transaction/BankGuarantee",
            qs.stringify(mydata),
            contentType,
            response => {
              resolve(response.data);
              this.data_bank_guarantee_notification = response.data;
              this.count_bank_guarantee_notification =
                this.user_role.toLowerCase() === "atasan"
                  ? response.data.length
                  : 0;
            },
            err => {
              err;
            }
          );
        });
      },
  
      dataSubmissionFormNotification() {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "R",
            Token: "R",
            UID: localStorage.getLocalStorage("UID"),
            Route: "NOTIFICATION_FOR_BANK_GUARANTEE",
            submission_type: "BGOI",
            status_cashing: 0
          };
  
          let contentType = `application/x-www-form-urlencoded`;
  
          const qs = require("qs");
  
          Services.PostData(
            ApiService,
            "Transaction/SubmissionForm",
            qs.stringify(mydata),
            contentType,
            response => {
              resolve(response.data);
              this.data_submission_form_finance_notification = response.data;
              this.count_submission_form_finance_notification =
                this.user_role.toLowerCase() === "atasan"
                  ? response.data.length
                  : 0;
            },
            err => {
              err;
            }
          );
        });
      },
  
      async load() {
        Promise.all([
          await this.dataEscrowPaymentAccepted(),
          await this.dataBankGuaranteeNotification(),
          await this.dataSubmissionFormNotification(),
          await this.dataSubWorkUnits()
        ]).then(function(results) {
          results;
        });
      },
  
      async loadDataSubWorkUnits() {
        Promise.all([await this.dataSubWorkUnits()]).then(function(results) {
          results;
        });
      },
  
      async loadDataComboboxEditItem() {
        Promise.all([await this.dataOrganizationalUnits_Combobox()]).then(function(results) {
          results;
        });
      },
  
      editItem(item) {
        this.editedIndex = this.data_sub_work_units.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.loadDataComboboxEditItem();
        this.dialog = true;
      },
  
      deleteItem(item) {
        this.deletedIndex = this.data_sub_work_units.indexOf(item);
        this.deletedItem = Object.assign({}, item);
        this.dialogDelete = true;
      },
  
      deleteItemConfirm() {
        this.data_sub_work_units.splice(this.deletedIndex, 1);
        this.closeDelete();
      },
  
      close() {
        this.dialog = false;
        this.$nextTick(() => {
          this.add_sub_work_units.organizational_unit_id = "";
          this.add_sub_work_units.organizational_unit_name = "";
          this.add_sub_work_units.work_unit_id = "";
          this.add_sub_work_units.work_unit_name = "";
          this.add_sub_work_units.sub_work_unit_id = "";
          this.add_sub_work_units.sub_work_unit_name = "";
          this.add_sub_work_units.sub_organizational_unit = "";
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },
  
      closeDelete() {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.deletedItem = Object.assign({}, this.defaultItem);
          this.deletedIndex = -1;
        });
      },
  
      save(formInput) {
        if (formInput == "add_sub_work_units") {
          if (this.editedIndex > -1) {
            Object.assign(
              this.data_sub_work_units[this.editedIndex],
              this.add_sub_work_units
            );
          } else {
            this.data_sub_work_units.push(this.add_sub_work_units);
          }
        } else if (formInput == "edit_sub_work_units") {
          if (this.editedIndex > -1) {
            Object.assign(
              this.data_sub_work_units[this.editedIndex],
              this.editedItem
            );
          } else {
            this.data_sub_work_units.push(this.editedItem);
          }
        }
        this.close();
      },
  
      initHeders() {
        this.headers = this.getHeaders();
      },
  
      initSubOrganizationalUnitCombobox() {
        this.data_sub_organizational_unit_combobox = this.getSubOrganizationalUnitCombobox();
      }
    }
  };
  </script>
  